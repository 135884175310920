/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    div: "div",
    h1: "h1"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Dentro de nada tengo un examen de programación así que en vez de ponerme en el windows a practicar me he informado de como compilar en Linux. El compilador por defecto de Linux es el gcc un programa que a los usuarios de windows les parecerá complicado, extraño e incluso arcaico y es que no estamos acostumbrados a utilizar la linea de comandos pero es igual que todo con un poco de practica todo se consigue."), "\n", React.createElement(_components.p, null, "Vamos primero a crear el programa en C. Abrimos un editor de texto, yo uso gedit, y escribimos. Va, uno clásico y sencillito:"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"c\"><pre class=\"language-c\"><code class=\"language-c\"><span class=\"token macro property\"><span class=\"token directive-hash\">#</span><span class=\"token directive keyword\">include</span><span class=\"token string\">&lt;stdio.h></span></span>\n\n<span class=\"token keyword\">void</span> <span class=\"token function\">main</span> <span class=\"token punctuation\">(</span><span class=\"token punctuation\">)</span><span class=\"token punctuation\">{</span> <span class=\"token function\">printf</span><span class=\"token punctuation\">(</span><span class=\"token string\">\"Hello World\\n\"</span><span class=\"token punctuation\">)</span><span class=\"token punctuation\">;</span> <span class=\"token punctuation\">}</span></code></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "Guardamos el programa como prueba.c y ya tenemos nuestro código fuente. Ahora el compilador. Vamos a la consola, nos aseguramos de estar en la carpeta en que tenemos guardado el código y escribimos:"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre class=\"language-bash\"><code class=\"language-bash\">gcc hello.c <span class=\"token parameter variable\">-o</span> hello</code></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "La sintaxis es siempre la misma a gcc le indicamos un archivo o mas de uno, lo compilara y guardara el ejecutable con el nombre que le indiquemos después del -o."), "\n", React.createElement(_components.p, null, "Si en el programa estamos utilizando la librería matemática o alguna que no sea estándar se lo tendremos que indicar al compilador con el parámetro -l. Por ejemplo la librería matemática seria -lm."), "\n", React.createElement(_components.h1, null, "Si queremos utilizar una librería que no se encuentre en el directorio por defecto con este mismo parámetro indicaremos primero el directorio y luego la librería. Por ultimo para ejecutar el archivo que hemos creado:"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre class=\"language-bash\"><code class=\"language-bash\">./hello</code></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "Con esto ya podemos empezar a compilar los programas mas básicos."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
